<template>
  <div>
    <div class="breadcrumb_header">
      <div class="breadcrumb">
        <h5 style="margin: 0">NeXa</h5>
        <span class="divider"></span>
        <span>Use Cases / Find Precedents</span>
      </div>
      <div class="open_sidebar" @click="toggleSidebar">
        <span class="material-symbols-rounded icon"
          >keyboard_double_arrow_left</span
        >
        <p>Open Sidebar</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-1 back-button">
        <div
          class="d-flex justify-content-start cursor-pointer back"
          @click="goBack"
        >
          <span class="material-symbols-rounded" style="margin: 0"
            >arrow_back</span
          >
          <span style="margin: 0 8px">Back to Precedent List</span>
        </div>
      </div>
      <div class="col-md-10">
        <section class="claims-container">
          <header class="claims-header">
            <div class="title-group">
              <h2 class="primary-title">Follow up Question:</h2>
              <h3 class="secondary-title">Summarize Claims only</h3>
            </div>
            <div class="action-buttons">
              <button class="action-btn copy-btn" tabindex="0">
                <img
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/a5fc1aca50cb553e4e813d1e66a4f62c328c94fc60417f9c7fb438b58331a1a1?placeholderIfAbsent=true&apiKey=411e2ec61bdb45ecb3af3338badbc5df"
                  alt="Copy content"
                  class="action-icon"
                />
                <span>Copy</span>
              </button>
              <button class="action-btn export-btn" tabindex="0">
                <span>Export</span>
                <img
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/4b955f8baffea27f6cbb767e69e961154af36693e882142d3307b31fc4ce528c?placeholderIfAbsent=true&apiKey=411e2ec61bdb45ecb3af3338badbc5df"
                  alt="Export content"
                  class="action-icon"
                />
              </button>
            </div>
          </header>

          <article class="claims-content">
            <p class="claims-description">
              For your question, Nexa has determined that a breach of contract
              is actionable when there is a significant violation that causes
              measurable damages, as outlined in New Zealand's Contract Law Act
              2017. The breach must have substantial impact on the contract's
              purpose to warrant legal action. Case law further confirms this by
              focusing on material breaches, and secondary sources highlight the
              courts' growing focus on practical consequences rather than
              technicalities. However, exceptions like the Doctrine of
              Substantial Performance and statutory time limits may limit your
              claim's success depending on the specifics of the breach.
            </p>
          </article>

          <section class="claims-table">
            <header class="table-header">
              <h4 class="column-title">Key Point</h4>
              <h4 class="column-title">Claim</h4>
            </header>

            <div class="table-row">
              <div class="row-label">Damages</div>
              <div class="row-content">
                Seeks $200,000 in compensatory damages for financial losses.
              </div>
            </div>

            <div class="table-row">
              <div class="row-label">Witness Testimonies</div>
              <div class="row-content">
                Witness A claims the agreement included a specific delivery
                date.
              </div>
            </div>

            <div class="table-row">
              <div class="row-label">Jurisdiction Issues</div>
              <div class="row-content">
                Plaintiff asserts jurisdiction in X, where the agreement was
                signed.
              </div>
            </div>

            <div class="table-row">
              <div class="row-label">Contract Clauses</div>
              <div class="row-content">
                Clause 12 specifies that delivery dates are essential to the
                contract.
              </div>
            </div>

            <div class="table-row">
              <div class="row-label">Breach Type</div>
              <div class="row-content">
                Plaintiff claims a material breach due to non-performance.
              </div>
            </div>

            <div class="table-row">
              <div class="row-label">Mitigating Factors</div>
              <div class="row-content">
                No mitigating factors exist to excuse late delivery.
              </div>
            </div>
          </section>
        </section>
        <section class="content">
          <header class="heading">
            <h1 class="heading-title">Smith v. Jones</h1>
            <div class="buttons">
              <button class="button">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/a5fc1aca50cb553e4e813d1e66a4f62c328c94fc60417f9c7fb438b58331a1a1?placeholderIfAbsent=true&apiKey=b7b3d02748134357b1767f32084c4983"
                  alt="Copy Icon"
                  class="icon"
                />
                <span class="button-title">Copy</span>
              </button>
              <button class="button-2">
                <span class="button-title">Export</span>
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/7a2cdc183600327c83299d2b6f88dda4ade5113046a027c61dbfad4d3ab8c35f?placeholderIfAbsent=true&apiKey=b7b3d02748134357b1767f32084c4983"
                  alt="Export Icon"
                  class="icon"
                />
              </button>
            </div>
          </header>
          <article class="case-info">
            <header class="header">Case Info</header>
            <div class="info-card">
              <div class="case">
                <span class="case-title">Case Title</span>
                <p class="case-detail">Smith v. Jones</p>
              </div>
              <div class="case">
                <span class="case-title">Citation</span>
                <p class="case-detail">123 F.3d 456 (2024)</p>
              </div>
            </div>
            <div class="info-card">
              <div class="case">
                <span class="case-title">Court</span>
                <p class="case-detail">U.S. Court of Appeals</p>
              </div>
              <div class="case">
                <span class="case-title">Date Decided</span>
                <p class="case-detail">March 5, 2024</p>
              </div>
            </div>
          </article>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/4ad81a182688703b1fff1bef3787724cc1de957c3535a492ebf0569fa04a6c97?placeholderIfAbsent=true&apiKey=b7b3d02748134357b1767f32084c4983"
            alt=""
            class="divider-image"
          />
          <section class="summary">
            <h2 class="section-heading">Case Overview</h2>
            <p class="section-detail">
              This case concerns a breach of contract between Smith (the
              plaintiff) and Jones (the defendant). Smith claimed that Jones
              failed to deliver services as per their agreement, resulting in
              financial losses. The court had to determine whether Jones's
              actions amounted to a material breach of contract and what the
              appropriate remedies would be.
            </p>
          </section>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/4ad81a182688703b1fff1bef3787724cc1de957c3535a492ebf0569fa04a6c97?placeholderIfAbsent=true&apiKey=b7b3d02748134357b1767f32084c4983"
            alt=""
            class="divider-image"
          />
          <section class="summary">
            <h2 class="section-heading">Legal Issues</h2>
            <ul class="issues-list">
              <li class="issue">
                <span class="issue-title"
                  >In 2022, Smith entered into a contract with Jones to provide
                  web development services for Smith's startup.</span
                >
              </li>
              <li class="issue">
                <span class="issue-title"
                  >The contract stipulated that Jones would deliver a fully
                  functional website by December 2022.</span
                >
              </li>
              <li class="issue">
                <span class="issue-title"
                  >By the deadline, Jones had not completed the project, citing
                  unforeseen technical difficulties.</span
                >
              </li>
              <li class="issue">
                <span class="issue-title"
                  >Smith terminated the contract and filed a lawsuit for breach
                  of contract, seeking damages for lost business
                  opportunities.</span
                >
              </li>
            </ul>
          </section>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/4ad81a182688703b1fff1bef3787724cc1de957c3535a492ebf0569fa04a6c97?placeholderIfAbsent=true&apiKey=b7b3d02748134357b1767f32084c4983"
            alt=""
            class="divider-image"
          />
          <section class="arguments">
            <h2 class="section-heading">Arguments</h2>
            <div class="argument">
              <h3 class="argument-title">Plaintiff (Smith)</h3>
              <p class="argument-detail">
                Smith argued that the failure to meet the deadline caused
                significant losses, including the inability to launch the
                startup on time. Smith contended that the breach was material
                because the contract explicitly stated that timely delivery was
                essential to the business’s success.
              </p>
            </div>
            <div class="argument">
              <h3 class="argument-title">Defendant (Jones)</h3>
              <p class="argument-detail">
                Jones argued that unforeseen technical issues and reliance on
                third-party vendors caused the delay, which should not be
                considered a material breach of contract.
              </p>
            </div>
          </section>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/4ad81a182688703b1fff1bef3787724cc1de957c3535a492ebf0569fa04a6c97?placeholderIfAbsent=true&apiKey=b7b3d02748134357b1767f32084c4983"
            alt=""
            class="divider-image"
          />
          <section class="ruling-section">
            <h2 class="section-heading">Ruling</h2>
            <p class="section-detail">
              The court ruled in favor of the plaintiff, Smith, finding that
              Jones's failure to meet the deadline constituted a material breach
              of contract.
            </p>
            <div class="key-reasoning">
              <h3 class="reasoning-title">Key Reasoning</h3>
              <ul class="reasoning-list">
                <li>
                  <span class="reasoning-detail">
                    The court noted that the "time is of the essence" clause in
                    the contract indicated that timely performance was crucial
                    to the agreement.
                  </span>
                </li>
                <li>
                  <span class="reasoning-detail">
                    Jones's reliance on third-party vendors did not excuse the
                    failure to deliver the services on time because Jones
                    assumed the responsibility for any subcontractors.
                  </span>
                </li>
                <li>
                  <span class="reasoning-detail">
                    The court awarded Smith $100,000 in damages, which reflected
                    the lost profits Smith suffered due to the delayed website
                    launch.
                  </span>
                </li>
              </ul>
            </div>
            <div class="conclusion">
              <h3 class="conclusion-title">Conclusion</h3>
              <p class="conclusion-detail">
                The court held that Jones was liable for breach of contract, and
                Smith was entitled to damages. The court emphasized that when a
                contract explicitly states that timely performance is critical,
                any failure to meet deadlines without valid justification can be
                considered a material breach.
              </p>
            </div>
            <div class="key-takeaways">
              <h3 class="takeaway-title">Key Takeaways</h3>
              <ul class="takeaway-list">
                <li>
                  <span class="takeaway-detail"
                    >The inclusion of a "time is of the essence" clause in
                    contracts is crucial in determining whether delays
                    constitute a material breach.
                  </span>
                </li>
                <li>
                  <span class="takeaway-detail">
                    Contractors are generally held accountable for the
                    performance of any third parties they hire to assist in
                    fulfilling their contractual obligations.
                  </span>
                </li>
              </ul>
            </div>
          </section>
        </section>
      </div>
    </div>
    <div class="wrapper-chatbox">
      <ChatBox @chat-submit="submitQuery" />
    </div>
    <sidebar
      v-if="sidebarCollapsed"
      @toggle-sidebar="toggleSidebar"
      :uploadFiles="files"
    />
  </div>
</template>
<script>
import sidebar from "../sidebar.vue";
import ChatBox from "../../../components/input/ChatBox.vue";
import nexa from "@/store/nexa.js";
import usecase from "@/store/usecase.js";
export default {
  components: {
    ChatBox,
    sidebar,
  },
  data() {
    return {
      loading: true,
      sidebarCollapsed: false,
    };
  },
  methods: {
    toggleSidebar() {
      this.sidebarCollapsed = !this.sidebarCollapsed;
    },
    goBack() {
      this.$router.push({ name: "ExpertMode" });
    },
    submitQuery(question) {
      const formData = new FormData();
      formData.append("language", "en");
      formData.append("location_id", 1);
      formData.append("question", question);

      this.blockUser = true;
      nexa
        .DetailAsk(this.$route.params.id, formData)
        .then((response) => {
          console.log(response);
          // const responseId = response.data.data.id;
          // console.log(response);
          // const responseId = response.data.data.id;

          if (response.data.completedPerCentage === 100) {
            this.blockUser = false;
          }
        })
        .catch((error) => {
          console.error("Error submitting query:", error);
          this.blockUser = false;
        });
    },
  },
  created() {
    usecase.Summary(this.$route.params.id, {
      content_key: this.$route.query.key,
      content_type: this.$route.query.type,
    });
  },
};
</script>
<style scoped>
.wrapper-chatbox {
  position: fixed;
  right: 2rem;

  width: 80%;
  z-index: 30;
  bottom: 2rem;
}

.claims-container {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 4px 10px rgba(227, 229, 235, 0.6);
  display: flex;
  flex-direction: column;
  font: 400 14px/1.5 Poppins, sans-serif;
  padding: 22px 24px;
  margin-bottom: 2rem;
}

.claims-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  flex-wrap: wrap;
}

.title-group {
  display: flex;
  min-width: 240px;
  align-items: center;
  gap: 8px;

  line-height: 28px;
}
.overlay-container {
  width: 25%;
  position: fixed;
  top: 8.7vh;
  right: 0;
  z-index: 1000;

  height: 100vh;
}
.primary-title {
  color: #0e4485;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.75rem;
}

.secondary-title {
  color: #383a3e;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.75rem;
}

.action-buttons {
  display: flex;
  align-items: center;
  gap: 16px;
}

.action-btn {
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  padding: 2px 8px;
  font-size: 14px;
  cursor: pointer;
  border: none;
  background: none;
}

.copy-btn {
  color: #0e4485;
}

.export-btn {
  background: #0e4485;
  color: #fff;
}

.action-icon {
  width: 18px;
  height: 18px;
}

.claims-content {
  margin: 32px 0;
}

.claims-description {
  color: #86888d;
  line-height: 24px;
  margin: 0;
}

.claims-table {
  border: 1px solid #86888d;
  border-radius: 8px;
  overflow: hidden;
}

.table-header {
  display: flex;
  background: #f2f3f3;
  border-bottom: 1px solid #86888d;
}

.column-title {
  flex: 1;
  min-width: 240px;
  padding: 9px 16px;
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  color: #383a3e;
}

.column-title:first-child {
  border-right: 1px solid #86888d;
}

.table-row {
  display: flex;
  border-bottom: 1px solid #86888d;
}

.row-label {
  flex: 1;
  min-width: 240px;
  padding: 9px 16px;
  border-right: 1px solid #86888d;
}

.row-content {
  flex: 1;
  min-width: 240px;
  padding: 9px 16px;
}

@media (max-width: 991px) {
  .claims-container {
    padding: 0 20px;
  }

  .claims-header,
  .table-header,
  .table-row {
    max-width: 100%;
    flex-wrap: wrap;
  }

  .title-group,
  .action-buttons,
  .column-title,
  .row-label,
  .row-content {
    max-width: 100%;
  }
}

.page-content {
  padding: 0;
}
.breadcrumb_header {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  background: #fff;
}

.breadcrumb {
  display: flex;
  align-items: center;
  margin: auto 0;
  span {
    font-size: 14px;
    color: var(--dark-grey) !important;
  }
}
.open_sidebar {
  height: 40px;
  background: var(--primary);
  display: inline-flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center; /* Ensure vertical alignment */
  gap: 8px;
  border-radius: 4px;
  cursor: pointer;
}

.open_sidebar span {
  color: #fff;
  display: flex; /* Ensure icon is aligned vertically */
  align-items: center; /* Align icon vertically within span */
}

.open_sidebar p {
  color: #fff !important;
  margin: 0; /* Remove any default margin to align the text properly */
  line-height: 1; /* Ensure text has the same line height as the icon */
  display: flex;
  align-items: center; /* Align text vertically */
}

.divider {
  display: inline-block;
  margin: 0px 16px;
  width: 1px;
  height: 32px;
  background-color: var(--mid-grey);
}
.back {
  color: var(--primary) !important;
  font-weight: 500;
  padding: 5px;
}
.col-md-10 {
  margin-top: 7rem;
}

.back-button {
  margin-top: 2rem;
  text-wrap: nowrap;
  padding-left: 2rem;
}
.content {
  border-radius: 8px;
  background: var(--Neutral-White, #fff);
  box-shadow: 0 2px 4px 0 rgba(170, 171, 175, 0.6);
  display: flex;
  flex-direction: column;
  padding: 22px 24px;
}
@media (max-width: 991px) {
  .content {
    padding: 0 20px;
  }
}
</style>
<style scoped>
.content {
  border-radius: 8px;
  background: var(--Neutral-White, #fff);
  box-shadow: 0 2px 4px rgba(170, 171, 175, 0.6);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-family: Poppins, sans-serif;
  padding: 22px 24px;
}
@media (max-width: 991px) {
  .content {
    padding: 0 20px;
  }
}
.heading {
  display: flex;
  align-items: center;
  gap: 40px;
  font-weight: 500;
  justify-content: space-between;
  flex-wrap: wrap;
}
.heading-title {
  font-size: 16px;
  color: var(--Primary-Blue, #0e4485);
}
.buttons {
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 14px;
}
.button,
.button-2 {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 2px 8px;
}
.button {
  border-radius: 4px;
  color: var(--Primary-Blue, #0e4485);
}
.button-2 {
  border-radius: 4px;
  background: var(--Primary-Blue, #0e4485);
  color: var(--Neutral-White, #fff);
}
.icon {
  width: 18px;
}
.case-info {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
}
.header {
  font-size: 16px;
  color: var(--Neutral-Black, #383a3e);
  font-weight: 500;
}
.info-card {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 16px;
  flex-wrap: wrap;
  padding: 4px 0;
}
.case {
  display: flex;
  flex-direction: column;
  min-width: 240px;
  flex: 1;
}
.case-title {
  color: var(--Neutral-Black, #383a3e);
  font-size: 16px;
  font-weight: 500;
}
.case-detail {
  color: var(--Neutral-Dark-Grey, #86888d);
  font-size: 14px;
  margin-top: 4px;
}
.divider-image {
  width: 100%;
  margin-top: 32px;
}
.summary,
.arguments,
.ruling-section {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
}
.section-heading {
  font-size: 16px;
  color: var(--Primary-Blue, #0e4485);
  font-weight: 500;
}
.section-detail,
.issue,
.argument,
.reasoning-detail,
.conclusion-detail,
.takeaway-detail {
  color: var(--Neutral-Black, #383a3e);
  font-size: 14px;
  line-height: 24px;
}
.issues-list,
.reasoning-list,
.takeaway-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.issue-title,
.reasoning-title,
.conclusion-title,
.takeaway-title {
  font-weight: 500;
}
</style>
